<template>
  <v-dialog v-model="dialog" max-width="600px" @click:outside="resetForm()">
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Event</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-form @submit.prevent="saveForm" method="post" id="event-form">
          <v-text-field
            label="Name"
            v-model="fields.name"
            ref="eventName"
            type="text"
            outlined
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-textarea
            label="Description"
            v-model="fields.description"
            rows="4"
            outlined
            :error="errors.hasOwnProperty('description')"
            :error-messages="errors['description']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="event-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      event: {},
      fields: {
        name: "",
        description: "",
      },
      errors: {},
    };
  },

  watch: {
    dialog() {
      if (this.dialog) {
        this.$nextTick().then(() => {
          this.$refs.eventName.focus();
        });
      }
    },
  },

  methods: {
    openForm: function (event = null) {
      if (event !== null) {
        this.isEditing = true;
        this.fields.name = event.name;
        this.fields.description = event.description;
        this.event = event;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.name = "";
      this.fields.description = "";
      this.event = {};
    },

    saveForm: function () {
      const appId = this.$route.params.id;
      this.loading = true;
      this.errors = {};

      let payload = {
        appId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.eventId = this.event.id;
      }

      this.$store
        .dispatch("drum/admin/saveEvent", {
          ...payload,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>